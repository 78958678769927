<template>
  <div class="cpt-login_footer">
    <img
      class="footer-left"
      src="~@/assets/images/bottom_left_light.png"
      alt="bottom_left_left"
    />
    <img
      class="footer-right"
      src="~@/assets/images/bottom_left_light.png"
      alt="bottom_left_light"
    />
    <img
      class="footer-light"
      src="~@/assets/images/bottom_light.png"
      alt="bottom_light"
    />
    <div class="company">盐城市公安局</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cpt-login_footer {
  width: 100%;
  height: 96px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-left {
    margin-right: 207px;
    flex: 1;
  }
  .footer-right {
    flex: 1;
    margin-left: 207px;
    transform: rotateY(180deg);
  }
  .footer-light {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    transform: translateY(calc(-100% + 84px));
  }
  .company {
    font: bold 40px "Microsoft YaHei";
    position: absolute;
    bottom: 1.6em;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    z-index: 999;
    letter-spacing: 0.2em;
    // font-size: 20px;
  }
}
</style>