<template>
  <div class="page-login">
    <Header />
    <Bg />
    <Form />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header";
import Bg from "./components/bg";
import Form from "./components/form";
import Footer from "./components/footer";
export default {
  components: { Header, Bg, Form, Footer },
};
document.title = "";
</script>

<style lang="scss" scoped>
.page-login {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
</style>